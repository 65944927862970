<template>
    <component :is="currentComponent"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Notifications",
    computed: {
        currentComponent() {
            if (this.$route.query.id)
                return 'ViewNotificationComponent'

            return 'ViewAllNotificationsComponent'
        },
    },
    components: {
        ViewAllNotificationsComponent: defineAsyncComponent(() =>
            import("@/views/settings/notifications/ViewAllNotifications")
        ),
        ViewNotificationComponent: defineAsyncComponent(() =>
            import("@/views/settings/notifications/ViewNotification")
        ),
    }
}
</script>

<style scoped>

</style>